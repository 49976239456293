import React from "react";
import { Button, FormRadio, Loader } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import lang from "../../images/lang.svg";
import rdv from "../../images/rdv.svg";

import {
  getFolder,
  getManageEGALocation,
  getAppointmentEga,
  addAppointmentEga,
} from "../../requests/home";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isExist: false,
      isCalendar: false,
      valueDate: "",
      valueTime: "",
      region: null,
      data: [],
      dataFolder: {},
      dataLocation: [],
      selectedLocation: null,
      selectedDate: null,
    };
  }

  componentDidMount() {
    this.getFolder();
  }

  getFolder = () => {
    const manageLocationId =
      this.props.manageLocationId && this.props.manageLocationId !== "0"
        ? this.props.manageLocationId.split(";")
        : null;

    this.setState({ isLoad: true }, () => {
      getFolder(this.props.folderId, (resultFolder) => {
        if (resultFolder.success) {
          this.setState(
            {
              dataFolder: resultFolder.data,
            },
            () => {
              if (!resultFolder.data.appointmentEgaId) {
                getManageEGALocation(
                  manageLocationId,
                  resultFolder.data.region,
                  (result) => {
                    if (result.success) {
                      this.setState({ isLoad: false, data: result.data });
                    } else {
                      this.setState({ isLoad: false }, () =>
                        alert("Une erreur est survenue")
                      );
                    }
                  }
                );
              } else {
                this.setState({ isLoad: false, isExist: true });
              }
            }
          );
        } else {
          this.setState({ isLoad: false }, () =>
            alert("Une erreur est survenue")
          );
        }
      });
    });
  };

  getAppointmentEga = () => {
    getAppointmentEga(
      this.state.data[this.state.selectedLocation].id,
      (result) => {
        if (result.success) {
          this.setState({ dataLocation: result.data });
        } else {
          alert("Une erreur est survenue");
        }
      }
    );
  };

  getIdxValueDate = (valueDate) => {
    if (this.state.selectedLocation !== null) {
      let result = null;

      this.state.data[this.state.selectedLocation].dates.forEach(
        (date, idx) => {
          if (date.date === valueDate) {
            result = idx;
          }
        }
      );

      return result;
    } else {
      return null;
    }
  };

  getDisabledTime = () => {
    let disabledTime = [];

    if (this.state.selectedDate !== null) {
      this.state.dataLocation.forEach((element) => {
        if (
          this.state.data[this.state.selectedLocation].dates[
            this.state.selectedDate
          ].date === element.date &&
          (this.state.data[this.state.selectedLocation].dates[
            this.state.selectedDate
          ].token === element.token ||
            !element.token)
        ) {
          disabledTime.push(element.hour);
        }
      });
    }

    return disabledTime;
  };

  addAppointmentEga = () =>
    this.setState(
      {
        isLoad: true,
      },
      () => {
        addAppointmentEga(
          this.props.folderId,
          this.state.data[this.state.selectedLocation].id,
          this.state.data[this.state.selectedLocation].dates[
            this.state.selectedDate
          ].token,
          this.state.valueDate,
          this.state.valueTime,
          this.props.userId,
          (result) => {
            if (result.success) {
              this.setState(
                {
                  isLoad: false,
                  isExist: true,
                },
                () => document.location.reload()
              );
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  this.getFolder();
                  alert(
                    "Désolé, un autre utilisateur vient de valider ce rendez-vous à l'instant, merci de choisir un autre créneau"
                  );
                }
              );
            }
          }
        );
      }
    );

  getArrayTime = (timesRange, timeStep) => {
    const disabledTime = this.getDisabledTime();

    let times = [];

    timesRange.forEach((range) => {
      const start = moment(range[0], "HH:mm");
      const end = moment(range[1], "HH:mm");
      let old = start;

      while (old.isBefore(end)) {
        if (!disabledTime.includes(old.format("HH:mm"))) {
          times.push(old.format("HH:mm"));
        }
        old = old.add(timeStep, "minutes");
      }
    });

    return times;
  };

  render() {
    let isFull = true;
    this.state.data.forEach((element) => {
      if (element.appointmentWait) {
        isFull = false;
      }
    });

    let arrayTime = [];

    if (this.state.selectedDate !== null) {
      arrayTime = this.getArrayTime(
        this.state.selectedDate !== null
          ? [
              [
                this.state.data[this.state.selectedLocation].dates[
                  this.state.selectedDate
                ].start1,
                this.state.data[this.state.selectedLocation].dates[
                  this.state.selectedDate
                ].end1,
              ],
              [
                this.state.data[this.state.selectedLocation].dates[
                  this.state.selectedDate
                ].start2,
                this.state.data[this.state.selectedLocation].dates[
                  this.state.selectedDate
                ].end2,
              ],
            ]
          : [],
        this.state.data[this.state.selectedLocation].interval
      );
    }

    return (
      <>
        <div className="main">
          <div className="body">
            <div className="header">
              <img
                src={lang}
                alt="logo lang"
                className="m-auto"
                style={{ maxHeight: "42px", maxWidth: "100%" }}
              />
            </div>
            <p>
              Bonjour {this.state.dataFolder.clientKind}{" "}
              {this.state.dataFolder.clientName},
              <br />
              <br />
              Nous avons été mandaté par votre assurance{" "}
              {this.state.dataFolder.company} pour le sinistre de votre véhicule{" "}
              {this.state.dataFolder.brand} {this.state.dataFolder.model}{" "}
              {this.state.dataFolder.color} immatriculé{" "}
              {this.state.dataFolder.licensePlate}.
              {this.state.isExist ? null : (
                <>
                  <br />
                  <br />
                  Merci de choisir une date de rendez-vous pour l'expertise de
                  votre véhicule.
                </>
              )}
            </p>
            <h4>Lieu rendez-vous d'expertise</h4>
            {!this.state.isExist ? (
              <>
                {isFull ? (
                  <p className="red">
                    Désolé, tous les rendez-vous ont déjà été réservés, dès que
                    de nouvelles places seront disponibles, nous vous
                    recontacterons.
                  </p>
                ) : null}
                {this.state.data.map((element, idx) =>
                  element.appointmentWait > 0 ? (
                    <div
                      key={idx}
                      className="d-flex p-10 mb-20"
                      style={{
                        backgroundColor: "#eeeeee",
                        borderRadius: "12px",
                        maxWidth: "500px",
                      }}
                    >
                      <FormRadio
                        checked={this.state.selectedLocation === idx}
                        onChange={() =>
                          this.setState(
                            {
                              selectedLocation: idx,
                              selectedDate: null,
                              valueDate: "",
                              valueTime: "",
                              dataLocation: [],
                            },
                            this.getAppointmentEga
                          )
                        }
                        className="mt-auto mb-auto mr-5"
                      />
                      <div>
                        <h5 className="m-0">{element.name}</h5>
                        <p className="m-0">{element.address}</p>
                        <p className="m-0">
                          {element.zipCode} {element.city}
                        </p>
                        {element.latitude && element.longitude ? (
                          <p className="m-0">
                            GPS : {element.latitude} / {element.longitude}
                          </p>
                        ) : null}
                        <p className="m-0">{element.phone}</p>
                      </div>
                    </div>
                  ) : null
                )}

                <h4>Date rendez-vous d'expertise</h4>
                <div className="d-flex">
                  {this.state.selectedLocation !== null ? (
                    this.state.data[this.state.selectedLocation].dates.map(
                      (element, idx) =>
                        moment(element.date).isAfter(moment()) ? (
                          <Button
                            key={idx}
                            className="w-auto mb-10 mr-10"
                            outline={idx !== this.state.selectedDate}
                            text={`${moment(element.date).format(
                              "DD/MM/YYYY"
                            )} (${
                              [
                                ...this.state.data[this.state.selectedLocation]
                                  .dates,
                              ]
                                .splice(0, idx + 1)
                                .filter(
                                  (subElement) =>
                                    subElement.date === element.date
                                ).length
                            })`}
                            onClick={() =>
                              this.setState({
                                valueDate: element.date,
                                selectedDate: idx,
                              })
                            }
                          />
                        ) : null
                    )
                  ) : (
                    <p>Veuillez sélectionner un lieu</p>
                  )}
                </div>

                <h4>Heure rendez-vous d'expertise</h4>
                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                  {this.state.selectedDate !== null ? (
                    arrayTime.length ? (
                      arrayTime.map((element, idx) => (
                        <Button
                          key={idx}
                          className="w-auto mb-10 mr-10"
                          outline={element !== this.state.valueTime}
                          text={element}
                          onClick={() => this.setState({ valueTime: element })}
                        />
                      ))
                    ) : (
                      <p className="red">
                        Aucun créneau disponible, veuillez sélectionner une
                        autre date
                      </p>
                    )
                  ) : (
                    <p>Veuillez sélectionner une date</p>
                  )}
                </div>

                <div className="d-flex ">
                  <Button
                    disabled={!this.state.valueDate || !this.state.valueTime}
                    text="VALIDER MON RDV"
                    className="w-auto ml-auto mb-20"
                    onClick={this.addAppointmentEga}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
              </>
            ) : (
              <div
                className="p-10 mb-20"
                style={{
                  backgroundColor: "#eeeeee",
                  borderRadius: "12px",
                }}
              >
                <h5 className="m-0">{this.state.dataFolder.name}</h5>
                <p className="m-0">{this.state.dataFolder.address}</p>
                <p className="m-0">
                  {this.state.dataFolder.zipCode} {this.state.dataFolder.city}
                </p>
                <p className="m-0">
                  Le <b>{this.state.dataFolder.appointmentEgaDate}</b> à{" "}
                  <b>{this.state.dataFolder.appointmentEgaHour}</b>
                </p>
              </div>
            )}
            <div
              className="bg-white d-flex mt-auto"
              style={{ margin: "0 -20px", marginTop: "0" }}
            >
              <img
                src={rdv}
                alt="illustration"
                className="ml-auto mr-auto"
                style={{ maxWidth: "100%" }}
              />
            </div>

            <div className="footer"></div>
          </div>
        </div>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
