import React from "react";

// Layout Types
import DashboardLayout from "./layouts/Dashboard";

// Route Views
import Home from "./views/dashboard/Home";
import Error from "./views/dashboard/Error";

const routes = [
  {
    path: "*",
    exact: true,
    layout: DashboardLayout,
    component: Error,
  },
  {
    path: "/",
    exact: true,
    layout: DashboardLayout,
    component: () => <Error />,
  },
  {
    path: "/:folderId",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  },
  {
    path: "/:folderId/:manageLocationId",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  },
  {
    path: "/:folderId/:manageLocationId/:userId",
    exact: true,
    layout: DashboardLayout,
    component: Home,
  },
];

export default routes;
