import axios from "../utils/axiosConfig";

const getFolder = (folderId, callback) => {
  axios("index/get-folder", { data: { folderId } })
    .then((res) => callback(res.data))
    .catch(() => callback({ success: false }));
};

const getManageEGALocation = (manageLocationId, region, callback) => {
  axios("index/get-manage-ega-location", { data: { manageLocationId, region } })
    .then((res) => callback(res.data))
    .catch(() => callback({ success: false }));
};

const getAppointmentEga = (manageEgaLocationId, callback) => {
  axios("index/get-appointment-ega", { data: { manageEgaLocationId } })
    .then((res) => callback(res.data))
    .catch(() => callback({ success: false }));
};

const addAppointmentEga = (
  folderId,
  manageEgaLocationId,
  token,
  date,
  hour,
  userId,
  callback
) => {
  axios("index/add-appointment-ega", {
    data: { folderId, manageEgaLocationId, token, date, hour, userId },
  })
    .then((res) => callback(res.data))
    .catch(() => callback({ success: false }));
};

export {
  getFolder,
  getManageEGALocation,
  getAppointmentEga,
  addAppointmentEga,
};
