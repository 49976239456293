import React from "react";

import lang from "../../images/lang.svg";
import rdv from "../../images/rdv.svg";

export default class Home extends React.Component {
  render() {
    return (
      <div className="main">
        <div className="body">
          <div className="header">
            <img
              src={lang}
              alt="logo lang"
              className="m-auto"
              style={{ maxHeight: "50px", maxWidth: "100%" }}
            />
          </div>
          <p className="m-auto">Erreur 404</p>
          <div
            className="bg-white d-flex mt-auto"
            style={{ margin: "0 -20px", marginTop: "0" }}
          >
            <img
              src={rdv}
              alt="illustration"
              className="ml-auto mr-auto"
              style={{ maxWidth: "100%" }}
            />
          </div>

          <div className="footer"></div>
        </div>
      </div>
    );
  }
}
